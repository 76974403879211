.splide__arrow {
  background: #104d9c;
}

.splide__arrow svg {
  fill: #fff;
  width: 1.2rem;
  height: 1.2rem;
}

.splide__pagination__page {
  background: #104d9c;
}

.splide__pagination__page.is-active {
  background: #093a7b;
}

.splide__arrow--next,
.splide__arrow--prev {
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}
