.scroll-bottom {
    width: 30px;
    height: 30px;
    line-height: 35px;
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 99;
    text-align: center;
    /* color: rgb(183, 34, 34); */
    font-size: 25px;
    cursor: pointer;
    border-radius: 50%;
    /* background: var(--flop-main); */
    transition: all 0.3s ease-in-out;
    
  }
.scroll-bottom-icon {
    width: 25px;
    height: 25px;
    line-height: 35px;
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 99;
    text-align: center;
    color: var(--flop-main);
    font-size: 25px;
    cursor: pointer;
    border-radius: 50%;
    background: #fff;
    transition: all 0.3s ease-in-out;
    /* align-self: center; */
  }
  
  
  .scroll-bottom:after {
    position: absolute;
    z-index: -1;
    content: "";
    text-align: center;
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
  }

  .scroll-top {
    width: 30px;
    height: 30px;
    line-height: 35px;
    position: fixed;
    bottom: 20px;
    right: 5px;
    z-index: 99;
    text-align: center;
    /* color: rgb(183, 34, 34); */
    font-size: 25px;
    cursor: pointer;
    border-radius: 50%;
    /* background: var(--flop-main); */
    transition: all 0.3s ease-in-out;
    
  }
.scroll-top-icon {
    width: 25px;
    height: 25px;
    line-height: 35px;
    position: fixed;
    bottom: 20px;
    right: 5px;
    z-index: 99;
    text-align: center;
    color: var(--flop-main);
    font-size: 25px;
    cursor: pointer;
    border-radius: 50%;
    background: #fff;
    transition: all 0.3s ease-in-out;
    /* align-self: center; */
  }
  
  
  .scroll-top:after {
    position: absolute;
    z-index: -1;
    content: "";
    text-align: center;
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
  }
  
  