:root {
  --flop-main: #104d9c;
}

html {
  min-width: 320px;
  height: 100vh;
  background-color: #104d9c;
  
}

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}



::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

.submit {
  cursor: pointer;
  border-radius: 5em;
  color: #fff;
  /* background: linear-gradient(to right, #9c27b0, #e040fb); */
  border: 0;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  /* font-family: "Ubuntu", sans-serif; */
  /* margin-left: 35%; */
  font-size: 13px;
  /* box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1); */
}

.welcome {
  border-radius: 5em;
  color: #fff;

  border: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;

  font-size: 13px;

  overflow: hidden;
  position: relative;
}

.shining {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
}

.shining::before {
  content: "";
  position: absolute;
  top: -50%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, #fff, transparent);
  filter: blur(8px);
  animation: shine 5s ease-in-out infinite;
  clip-path: circle(100% at center);
}

@keyframes shine {
  0%,
  100% {
    transform: translateX(-50%) rotate(45deg);
    filter: blur(20px);
    opacity: 0.5;
  }
  50% {
    transform: translateX(50%) rotate(90deg);
    filter: blur(20px);
    opacity: 0.5;
  }
}


.high-light {
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}



