/* SearchAnimation.css */

@keyframes placeholderAnimation {
    0%, 100% { opacity: 1; }
    25% { opacity: 0.5; }
    50% { opacity: 0.3; }
    75% { opacity: 0.5; }
  }
  input::placeholder {
    font-size: var(--placeholder-font-size, 14px);
  }
  input::-webkit-input-placeholder {
    /* WebKit/Blink browsers */
    animation: placeholderAnimation 1.5s infinite;
  }
  
  input::-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    animation: placeholderAnimation 1.5s infinite;
  }
  
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    animation: placeholderAnimation 1.5s infinite;
  }
  
  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    animation: placeholderAnimation 1.5s infinite;
  }
  
  input::-ms-input-placeholder {
    /* Microsoft Edge */
    animation: placeholderAnimation 1.5s infinite;
  }
  