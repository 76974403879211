
@tailwind base;
@tailwind components;
@tailwind utilities;

.opt-container {
  @apply flex justify-between gap-1 ;
}
.opt-container input {
  @apply !mr-0 flex-1 py-1 bg-white  rounded-md;
}


.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-page-loader .lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  background-image: url(assets/images/flopLogoDark.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px;
  animation: lds-dual-logo 1.2s linear infinite;
}

.full-page-loader .lds-dual-ring:after {
  content: "";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #104d9c;
  border-color: #104d9c transparent #104d9c transparent;
  box-shadow: 0 0 5px rgba(16, 77, 156, 0.2);
  animation: lds-dual-ring 1.2s linear infinite;
}

.wait {
  animation: lds-dual-wait 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-logo {
  0% {
    transform: scale(100%);
  }
  50% {
    transform: scale(150%);
  }
  100% {
    transform: scale(100%);
  }
}

@keyframes lds-dual-wait {
  0% {
    transform: scale(100%);
  }
  50% {
    transform: scale(110%);
  }
  100% {
    transform: scale(100%);
  }
}





@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSedenavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-white;
  }
}

/* ============= NavLink active Start here ============== */
nav .active li {
  color: black;
  font-weight: 600;
  text-decoration: 1px underline black;
}

@media screen and (max-width: 665px) {
  nav .active li {
    color: white;
    font-weight: 600;
    text-decoration: 1px underline white;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
/* ============= NavLink active End here ================ */
@layer base {
  * {
    margin: 0;
    padding: 0;
  }

  /* container */
  .nike-container {
    @apply w-11/12 m-auto;
  }
  
 
   
  /* Theme-Colors */
  .bg-theme {
    background-image: linear-gradient( 0.8deg,  rgb(255, 255, 255) 4.8%, rgba(16, 77, 156, 1) 50.5% );
  }
  

  /* Clip-path */
  .clip-path {
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 45%);
  }  
  
  /* Blur-Effect */
  .blur-effect-theme {
    @apply bg-white/50 bg-clip-padding backdrop-filter backdrop-blur bg-opacity-50 opacity-100 z-[40] shadow-sm shadow-slate-100 transition-all duration-300;
  }
  

  .blur-effect-theme-black {
    @apply bg-flopdarkColor bg-clip-padding backdrop-filter backdrop-blur opacity-70 z-[40] shadow-sm shadow-slate-700 transition-all duration-300;
  }

  /* Icon Stles */
  .icon-style {
    @apply h-6 w-6 sm:h-5 sm:w-5 text-slate-100 cursor-pointer transition-all duration-100 active:scale-110 leading-tight;
  }

  /* text-gradient */
  .text-gradient {
    @apply text-transparent bg-clip-text bg-gradient-to-tr from-blue-500 to-sky-500;
  }

  .text-gradient-body {
    @apply text-transparent bg-clip-text bg-gradient-to-tr from-blue-500 to-red-500;
  }
  .text-gradient-body-reverse {
    @apply text-transparent bg-clip-text bg-gradient-to-tl from-red-500 to-blue-500;
  }

  /* button */
  .button-theme {
    @apply px-7 py-1 rounded active:scale-90 transition-all duration-100 ease-in-out shadow-md sm:text-sm;
  }

  /* tranistions-theme */
  .transitions-theme {
    @apply transition-all duration-700 ease-in-out cursor-pointer;
  }

}

@layer utilities {
  .shadow-top:hover {
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2); /* Adjust the values for your desired shadow */
  }
  
  
}



/* CSS file or <style> tag in your component */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #6D6D6D inset; /* Safari/Chrome specific */
          box-shadow: 0 0 0 30px #6D6D6D inset;
          border: 5px solid  #6D6D6D; /* Hide the border */ /* Standard property */
  -webkit-text-fill-color: #262626;
          


        }



/* modal style start */
.wrapper {
  max-width: 1350px;
  padding: 0px 60px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}

.wrapper img {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
}
.overlay img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}
.overlay-arrows_left {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
}
/* modal style end */




