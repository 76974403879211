.custom-file-upload {
    /* display: inline-block; */
    margin-top: 10px;
  }
  
  .custom-file-upload label {
    
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .custom-file-upload input[type="file"] {
    display: none;
  }
  